import { ref } from 'vue';

export function useVersionCheck(checkInterval = 10000) {
    const currentVersion = ref(null);
    const isOutdatedVersion = ref(false);

    const controller = new AbortController();
    const signal = controller.signal;
    let intervalId = null;

    const checkVersion = async () => {
        try {
            const response = await fetch(`/version.json?t=${Date.now()}`, { cache: 'reload', signal });
            const data = await response.json();

            if (data.version !== currentVersion.value) {
                if (!currentVersion.value) {
                    currentVersion.value = data.version;
                } else {
                    isOutdatedVersion.value = true;
                }
            }
        } catch (error) {
            console.error('Failed to check version:', error);
        }
    };

    const startChecking = () => {
        if (!intervalId) {
            checkVersion();
        }

        intervalId = setInterval(checkVersion, checkInterval);
    };

    const stopChecking = () => {
        controller.abort();
        clearInterval(intervalId);
        intervalId = null;
    };

    const refreshNow = () => {
        isOutdatedVersion.value = false;
        window.location.assign(`${window.location.href  }&refresh=${  Date.now()}`);
        stopChecking();
    };

    const refreshLater = () => {
        isOutdatedVersion.value = false;
        stopChecking();
    };

    return { refreshNow, refreshLater, startChecking, stopChecking, isOutdatedVersion };
}
