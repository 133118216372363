import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import primevue from 'primevue/config'
import './assets/main.scss'
import 'primeflex/primeflex.scss'
import ToastService from 'primevue/toastservice'
import DialogService from 'primevue/dialogservice'
import { createPinia } from 'pinia'
import VueGtag from 'vue-gtag'

const pinia = createPinia()
const app = createApp(App)

app.use(ToastService)
app.use(DialogService)
app.use(router)
app.use(primevue)
app.use(pinia)

if (import.meta.env.MODE === 'production') {
    app.use(VueGtag, {
        config: { id: 'UA-119828770-2' },
    })
}

app.config.errorHandler = (err, vm, info) => {
    console.error('Erreur interceptée par le gestionnaire global:', err, vm, info);
}
app.config.warnHandler = (err, vm, info) => {
    console.warn('Warning interceptée par le gestionnaire global:', err, vm, info);
}


Object.values(import.meta.glob('./modules/*.ts', { eager: true })).forEach((module) => {
    module.install({ app, router })
})

app.mount('#app')


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then(() => {
        ;
    }).catch(() => {
        ;
    });
}

