import { useFetch } from '~/utils/fetch'
import { env } from '../env'

function buildParams(data) {
    const params = new URLSearchParams()

    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((value) => params.append(key, (value || '').toString()))
        } else if(typeof value !== 'object' && value !== '' && value !== null && value !== undefined) {
            params.append(key, value.toString())
        }
    })

    return params
}

export const getPaginatedLocations = (options: any = { rows: 10, first: 10 }) => {
    const params = buildParams({
        ...options,
        pagination: true,
        limit: options.rows || 10,
        offset: options.first || 0,
        include: ['groups', 'brand'],
    })

    if (options.downloadFileType) {
        params.delete('limit')
        params.delete('offset')

        return useFetch(`${env.BACKEND_V2_URL}/locations?${params}`).get().blob()
    }

    return useFetch(`${env.BACKEND_V2_URL}/locations?${params}`).get().json()
}

export const getAllLocations = (options: any = { pagination: true }) => {
    const params = buildParams({
        ...options,
        include: 'groups',
        pagination: false,
    })

    return useFetch(`${env.BACKEND_V2_URL}/locations?${params}`).get().json()
}

export const getTop15LocationsSales = (options: any = { pagination: true }) => {
    const params = buildParams({
        ...options,
        groupBy: 'location',
        sort: 'netSales',
        expandGk: true,
        limit: options.rows || 10,
    })

    return useFetch(`${env.BACKEND_V2_URL}/sales/net?${params}`).get().json()
}

export const getTop10HourlySales = (options: any = { pagination: true }) => {
    const params = buildParams({
        ...options,
        includeTotals: true,
        limit: options.rows || 10,
        alignStartOfDay: true
    })

    return useFetch(`${env.BACKEND_V2_URL}/sales/hourly?${params}`).get().json()
}

export const getAllLocationsSearch = (options: any = { rows: 10, first: 10 }) => {
    if(options.status === '-1') {
        delete options.status;
    }
    const params = buildParams({
        ...options,
        pagination: true,
        limit: options.rows || 10,
        offset: options.first || 0,
        include: 'groups',
    })

    if (options.downloadFileType) {
        params.delete('pagination')
        params.delete('limit')
        params.delete('offset')

        return useFetch(`${env.BACKEND_V2_URL}/locations/search?${params}`).get().blob()
    }

    return useFetch(`${env.BACKEND_V2_URL}/locations/search?${params}`).get().json()
}

export const getLocationById = (id: string) => {
    return useFetch(`${env.BACKEND_V2_URL}/locations/${id}`).get().json()
}

export const getLocationPermissions = (id: string, options: any = { rows: 10, first: 10 }) => {
    const params = buildParams({
        ...options,
        pagination: true,
        limit: options.rows || 10,
        offset: options.first || 0,
    })

    return useFetch(`${env.BACKEND_V2_URL}/locations/${id}/permissions?${params}`).get().json()
}

export const getEmployeesSales = (locationId: string, options: any = { rows: 10, first: 10 }) => {
    const params = buildParams({
        ...options,
        pagination: true,
        limit: options.rows || 10,
        offset: options.first || 0,
    })

    if (options.downloadFileType) {
        params.delete('limit')
        params.delete('offset')

        return useFetch(`/locations/${locationId}/employees/sales?${params}`).get().blob()
    }

    return useFetch(`/locations/${locationId}/employees/sales?${params}`).get().json()
}

export const getLocationGroups = (id: string, options: any = {}) => {
    const params = buildParams({
        ...options,
    })

    return useFetch(`${env.BACKEND_V2_URL}/locations/${id}/groups?${params}`).get().json()
}

export const getLocationEmployeesSales = (id: string, options: any = {}) => {
    const params = buildParams({
        limit: options.rows || 10,
        offset: options.first || 0,
        ...options,
    })

    return useFetch(`/locations/${id}/employees/sales?${params}`).get().json()
}

export const patchLocation = (id: string, body: object) => {
    const result = useFetch(`${env?.BACKEND_V2_URL}/locations/${id}`).patch(body)

    return result.text()
}
