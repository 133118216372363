import { useFetch } from '@vueuse/core'
import { env } from '../env'
import { DateTime } from 'luxon'
import {KJUR} from 'jsrsasign'
import { parse as uuidParse, v1 as uuidv1 } from 'uuid';

const velwebOriginID = "velweb"

export const authenticate = ({ email, password }: any) => {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    const headers = createOriginTokenHeaders()

    return useFetch(env.BACKEND_V2_URL + '/users/authenticate',{
        headers
      })
    .post({ email, password })
    .text()
}

const createOriginTokenHeaders = () => {
    const clientDateTime = DateTime.now().toISO()
    const id = uuidv1()
    const idStr = String.fromCharCode(...uuidParse(id))
    const idB64 = btoa(idStr);

    const key = `${env.CLIENT_ORIGIN_KEY}.${clientDateTime}.${velwebOriginID}.${idB64}`
    const data = { originId: velwebOriginID, id: id }
    const header = { alg:"HS256", typ:"JWT" }
    let token = ""

    try {
        token = KJUR.jws.JWS.sign(null, header, data, key);
    } catch(err) {
        console.error(err)
    }

    return { 'Velcloud-SignedOrigin': token, "Velcloud-ClientTime": clientDateTime }
}

export const resetPasswordTokenUnauthenticated = (email: string, options: {}) => {
    const params = new URLSearchParams({
        ...options,
    })

    return useFetch(env.BACKEND_V2_URL + `/users/unauthenticated/${email}/resetToken?${params}`)
        .post()
        .text()
}

export const resetPassword = (body: any = {}) => {
    return useFetch(env.BACKEND_V2_URL + `/users/resetPassword`)
        .post(body)
        .text()
}
