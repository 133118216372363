import { watchEffect } from 'vue'

export const vAutoScrollRight = {
    created: async (el, binding) => {
        const options = binding.value || {}
        const { smooth = false } = options
        const scrollOptions = {
            left: el.scrollWidth + 5000,
            behavior: smooth ? 'smooth' : 'auto',
        }

        el.classList.add('vAutoScrollRight')

        watchEffect(() => {
            el.scroll(scrollOptions)
        })

        el.observer = new MutationObserver(async () => {
            await nextTick()
            el.scroll(scrollOptions)
        })

        const observerOptions = {
            childList: true,
            attributes: true,
            subtree: true,
        }

        el.observer.observe(el, observerOptions)

        setTimeout(() => {
            el.scroll(scrollOptions)
        }, 1000)
    },
    updated: (el) => {
        el.scrollLeft = el.scrollWidth + 5000
    },
    unmounted: (el) => {
        if (el.observer) {
            el.observer.disconnect()
        }
    },
}
