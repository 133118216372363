<script setup>
import { useErrorStore } from "~/stores/error-store";

const { t } = useI18n();

const isVisible = ref(true);

const { isError, errorMessage} = storeToRefs(useErrorStore())
const { clearError } = useErrorStore()

function close() {
    clearError();
}

watch(isError, (value) => {
    isVisible.value = value;
}, { immediate: true });
</script>

<template>
    <ConfirmationMessage v-model="isVisible" :show-cancel="true" :show-confirm="false" title="An error has occurred" @cancel="close" @confirm="close">
        {{ t(errorMessage) }}
    </ConfirmationMessage>
</template>



