import { acceptHMRUpdate, defineStore } from 'pinia'
import { getUserSettings } from '~/services/users.service'
import { get } from 'lodash'
import { useStorage } from '@vueuse/core'

export const useSettingsStore = defineStore('settings', () => {

    useStorage('user', {});

  const settings = useStorage('settings', {})

    const updateSettings = async () => {
        const data = await getUserSettings()

        settings.value = Object.keys(data || {}).reduce((a, b) => {
            try {
                a[b] = JSON.parse(data[b])
            } catch (e) {
                a[b] = data[b]
            }

            return a
        }, {})
    }

    const getSetting = (key, defaultValue = null) => {
        return  get(settings.value, key, defaultValue)
    }

    return { settings, updateSettings, getSetting }
})

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot))
