import { createSharedComposable } from '@vueuse/core'

let socket = null

const SOCKET_HOST = import.meta.env.PROD ? 'ws://app.posveloce.com:8880' : 'ws://127.0.0.1:8880';

export const useDevMode = createSharedComposable(() => {
    const isEnabled = useLocalStorage('devMode', false)
    const breakAjaxRequest = useLocalStorage('breakAjaxRequest', false)
    const breakExport = useLocalStorage('breakExport', false)
    const focusOnContent = useLocalStorage('focusOnContent', false)
    const enableCaching = useLocalStorage('enableCaching', false)
    const showExecutionTime = useLocalStorage('showExecutionTime', false)
    const verboseApiError = useLocalStorage('verboseApiError', false)
    const stayAlert = useLocalStorage('stayAlert', false)
    const alerts = useLocalStorage('alerts', [])

    const workerStatus = ref('unknown') // État initial
    const newWorker = ref(null)
    const isActive = ref(false)
    const isSocketConnected = ref(false)
    const realTimeNotifications = ref(null);
    const desktopNotificationsEnabled = ref(false);
    const disableDesktopNotifications = ref(false);

    if (breakAjaxRequest.value) {
        isEnabled.value = false
    }

    const cacheItemCount = ref(0)
    const cacheSize = ref(0)

    const usageAllowed = computed(() => {
        const host = window.location.hostname
        const velocePattern = /\.veloce\.dev$/

        return host === '127.0.0.1' || host === 'localhost' || (velocePattern.test(host) && host !== 'next.veloce.dev')
    })

    const devHttpHeaders = computed(() => {
        return {
            'X-Dev-Mode': isEnabled.value ? 'enabled' : 'disabled',
            'X-Break-Ajax-Request': breakAjaxRequest.value ? 'enabled' : 'disabled',
            'X-Break-Export': breakExport.value ? 'enabled' : 'disabled',
            'X-Cache-Enabled': enableCaching.value ? 'enabled' : 'disabled',
            'X-Show-Execution-Time': showExecutionTime.value ? 'enabled' : 'disabled',
            'X-Verbose-Api-Error': verboseApiError.value ? 'enabled' : 'disabled',
            'X-Stay-Alert': stayAlert.value ? 'enabled' : 'disabled',
            'X-Focus-Content': focusOnContent.value ? 'enabled' : 'disabled',
        }
    })

    const getCacheInfo = async () => {
        try {
            const cacheName = 'persistent-cache-v1' // Nom de votre cache
            const cache = await caches.open(cacheName)

            // Obtenir toutes les requêtes dans le cache
            const requests = await cache.keys()

            cacheItemCount.value = requests.length

            // Calculer la taille du cache
            let totalSize = 0

            // Utiliser Promise.all pour récupérer la taille de chaque réponse
            await Promise.all(
                requests.map(async (request) => {
                    const response = await cache.match(request)

                    if (response) {
                        const clonedResponse = response.clone()
                        const body = await clonedResponse.blob() // Convertir en Blob pour obtenir la taille

                        totalSize += body.size // Ajouter la taille du blob au total
                    }
                }),
            )

            cacheSize.value = totalSize // Mettre à jour la taille totale
        } catch (error) {

        }
    }

    let cacheInfoHandler = null

    watch(
        stayAlert,
        (alertEnabled) => {
            if (!alertEnabled && socket) {
                socket.close()
            } else if (alertEnabled && !socket) {

                socket = new WebSocket(SOCKET_HOST)

                socket.onmessage = (event) => {
                    const notification = JSON.parse(event.data)


                    const alert = {
                        title: notification.action,
                        id: new Date().getTime(),
                        notification
                    }

                    if(notification.action === 'closed') {
                        alert.title = `Closed Pull Request #${  notification.number}`;
                    }

                    if(notification.action === "reopened") {
                        alert.title = `Reopened Pull Request #${  notification.number}`;
                    }

                    if(notification.action === "opened") {
                        alert.title = `New Pull Request #${  notification.number}`;
                    }

                    console.log(alert);






                    realTimeNotifications.value = alert
                    alerts.value.push(alert);
                }

                socket.onerror = (error) => {

                }

                socket.onclose = () => {
                    socket = null
                    isSocketConnected.value = false

                }

                socket.onopen = () => {
                    isSocketConnected.value = true

                    socket.send(JSON.stringify({ type: 'join_room', room: 'github_notifications' }))
                }

            }
        },
        { immediate: true },
    )
    watchEffect(() => {
        isSocketConnected.value = socket && socket.readyState === WebSocket.OPEN
        disableDesktopNotifications.value = Notification.permission === 'denied'
    })

    watch(desktopNotificationsEnabled, () => {
        if (desktopNotificationsEnabled.value) {
            requestNotificationPermission();
        }
    })

    watch(
        enableCaching,
        (enableCaching) => {
            if (enableCaching) {
                cacheInfoHandler = setInterval(() => {
                    getCacheInfo()
                }, 5000)
            } else {
                clearInterval(cacheInfoHandler)
            }

            navigator.serviceWorker.ready.then((registration) => {


                if (registration.active)
                    registration.active.postMessage({
                        type: 'SET_CACHE_ENABLED',
                        enabled: enableCaching,
                    })
                // Récupérer les informations sur le cache après avoir mis à jour le paramètre
                getCacheInfo()
            })
        },
        { immediate: true },
    )

    const clearCache = () => {
        navigator.serviceWorker.ready.then((registration) => {


            registration.update()
            if (registration.active) registration.active.postMessage({ type: 'CLEAR_CACHE' })
        })
    }

    const updateStatus = (registration) => {
        if (registration.waiting) {
            workerStatus.value = 'waiting'
            newWorker.value = registration.waiting
        } else if (registration.installing) {
            workerStatus.value = 'installing'
        } else if (registration.active) {
            workerStatus.value = 'active'
        }
    }

    // Activer le nouveau service worker en attente
    const activateNewWorker = () => {
        if (newWorker.value) {
            newWorker.value.postMessage({ type: 'SKIP_WAITING' })
        }
    }

    watch(isEnabled, (isEnabled) => {
        if (isEnabled) {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((registration) => {


                    updateStatus(registration)

                    // Surveiller les mises à jour du service worker
                    registration.addEventListener('statechange', () => {

                        newWorker.value = registration.installing
                        newWorker.value.addEventListener('statechange', () => {
                            updateStatus(registration)
                        })
                    })

                    navigator.serviceWorker.ready.then((registration) => {

                        updateStatus(registration)

                        if (registration.active)
                            registration.active.postMessage({
                                type: 'SET_CACHE_ENABLED',
                                enabled: enableCaching.value,
                            })
                        getCacheInfo() // Récupérer les informations sur le cache après enregistrement
                    })
                })
                .catch((error) => {

                })

            navigator.serviceWorker.addEventListener('controllerchange', () => {

                workerStatus.value = 'activated'
                newWorker.value = null
            })
        } else {
            navigator.serviceWorker.ready.then((registration) => {


                if (registration.active)
                    registration.active.postMessage({
                        type: 'SET_CACHE_ENABLED',
                        enabled: isEnabled,
                    })
            })

            navigator.serviceWorker
                .getRegistrations()
                .then((registrations) => {
                    for (const registration of registrations) {
                        registration.unregister()


                    }
                })
                .catch((error) => {

                })
        }
    })

    let controllerInterval = null

    if (!controllerInterval) {
        controllerInterval = setInterval(async () => {

            isActive.value = !!navigator.serviceWorker.controller && !!(await navigator.serviceWorker.ready).active
        }, 1000)
    }

    getCacheInfo()


    const showDesktopNotification = (notification) => {
        if (Notification.permission === 'granted') {
            new Notification(notification.title || 'New Notification', {
                body: notification.message,
                icon: notification.icon || '/default-icon.png',
            });
        } else {
            ;
        }
    };

    const requestNotificationPermission = () => {
        alert(Notification.permission );

        if (Notification.permission === 'default') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    ;
                } else {
                    ;
                }
            });
        }
    };

    const toggleDesktopNotifications = (enabled) => {
        desktopNotificationsEnabled.value = enabled;

        if (enabled) {
            requestNotificationPermission();
        }
    };

    const alertCount = computed(() => {
        return alerts.value.length
    })

    alerts.value = [];

    return {
        isEnabled,
        breakAjaxRequest,
        breakExport,
        focusOnContent,
        enableCaching,
        usageAllowed,
        devHttpHeaders,
        verboseApiError,
        clearCache,
        cacheItemCount,
        cacheSize,
        getCacheInfo,
        showExecutionTime,
        activateNewWorker,
        stayAlert,
        workerStatus,
        isActive,
        isSocketConnected,
        alerts,
        alertCount,
        realTimeNotifications,
        desktopNotificationsEnabled,
        disableDesktopNotifications
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(() => {

    })
    import.meta.hot.dispose(async () => {})
}
