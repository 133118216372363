import { ref, watch } from 'vue';
import { createSharedComposable } from '@vueuse/core';
import { tryOnMounted } from '@vueuse/core'
import router from '~/router';

export const useExecutionTime = createSharedComposable(() => {

    const loadingInProgress = ref(false);
    const loadingRefs = ref([]);
    const totalRequests = refWithControl(0)
    const executionTime = ref(0); // Variable pour stocker le temps d'exécution
    const contentLength = ref(0);
    let startTime = 0; // Variable pour stocker le temps de début
    let requests = 0;
    // Surveille les variables isLoading pour détecter le début et la fin du chargement


    watch(
        () => loadingRefs.value,
        (newValues) => {
            if(!newValues.length) {
                return;
            }
            const anyLoading = newValues.some(value => isRef(value) ? value.value : value);

            if (anyLoading && !loadingInProgress.value) {
                // Au moins une variable isLoading est vraie et loadingInProgress n'est pas encore vrai
                loadingInProgress.value = true;
                totalRequests.value = 0;
                contentLength.value = 0;
                requests = 0;

                // Initialiser le temps d'exécution
                startTime = Date.now();
            } else if (!anyLoading && loadingInProgress.value) {
                // Toutes les variables isLoading sont fausses
                totalRequests.value = requests;
                loadingInProgress.value = false;

                // Calculer le temps d'exécution
                executionTime.value = Date.now() - startTime; // Temps écoulé en ms
                console.log(`Execution time: ${executionTime.value} ms`); // Afficher le temps d'exécution
            }
        },
        { immediate: true, deep: true }
    );

    router.isReady().then(() => {
        router.beforeEach((to, from, next) => {
            if(to.name !== from.name) {
                loadingInProgress.value = false;
                totalRequests.value = 0;
                executionTime.value = 0;
                contentLength.value = 0;
                loadingRefs.value = [];
                requests = 0;
            }

            next();
        });
    })

    const isTracked = computed(() => {
        return loadingRefs.value.length > 0;
    });

    const incrementRequest = () => {
        requests++;
    }

    const addContentLength = (length) => {
        console.log('xxxxxxxxxxxxxx', length);
        if(loadingRefs.value.length) {
            contentLength.value = contentLength.value + Number(length);
        }
    }

    const setLoadingReferences = (isLoadingRefs = []) => {
        loadingRefs.value = isLoadingRefs;
    };

    return {
        loadingInProgress,
        loadingRefs,
        setLoadingReferences,
        addContentLength,
        incrementRequest,
        totalRequests,
        contentLength,
        isTracked,
        executionTime // Exposer executionTime pour utilisation externe
    };
});
