import { ref, computed } from 'vue';
import { createSharedComposable } from "@vueuse/core";

export const useApiErrorLogger = createSharedComposable(() => {
    const { verboseApiError } = storeToRefs(useDevMode());
    // Liste réactive des erreurs
    const errors = ref([]);

    // Fonction pour ajouter une erreur dans la liste
    function addError(statusCode, title, url, body) {
        if(verboseApiError.value) {
            errors.value.push({ statusCode, title, url, body });
        }
    }

    // Fonction pour vider les erreurs si nécessaire
    function clearErrors() {
        errors.value = [];
    }

    // Nombre d'erreurs, calculé automatiquement
    const errorCount = computed(() => errors.value.length);

    return {
        errors,
        addError,
        clearErrors,
        errorCount
    };
});
