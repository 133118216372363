import { acceptHMRUpdate, defineStore } from 'pinia'
import router from '../router'
import { ref } from 'vue'
import { authenticate, resetPassword, resetPasswordTokenUnauthenticated } from '../services/auth'
import { i18n } from '../modules/i18n'
import { getUserPermissions } from '~/services/users.service'
import { useSettingsStore } from '~/stores/settings'
import { useMultiSelectorStore } from "~/stores/multi-selector";

export const useAuthStore = defineStore('auth', () => {
    const { reset } = useMultiSelectorStore();
    const user = ref(JSON.parse(localStorage.getItem('user')))
    const returnUrl = ref(null)
    const previousToken = ref(null)
    const isAdmin = ref(false)
    const userPermissions = ref(null);

    const { updateSettings } = useSettingsStore()

    const setToken = (token) => {
        previousToken.value = token

        return previousToken.value
    }

    const logout = async (returnToPreviousUrl = false) => {

        if(returnToPreviousUrl) {
            returnUrl.value = router.currentRoute.value.fullPath
        }

        reset(true);

        user.value = null
        localStorage.removeItem('user')
        await router.push('/login')
        window.location.reload()
    }

    const updateUserPermissions = async () => {
        userPermissions.value = await getUserPermissions(user.value.id)

        await updateSettings()

        isAdmin.value = (userPermissions.value.data.global || []).some((access) => access.permission === 'fullAccess')
    }

    const login = async ({ form, resolve, reject }) => {
        const { onFetchError, onFetchResponse, statusCode, data } = authenticate(form)
        const { t } = i18n.global

        reset(true);

        onFetchError(() => {
            if (statusCode.value === 401) {
                reject(t('Email or password is incorrect'))
            } else if (statusCode.value !== 200) {
                reject(t('Something went wrong. Please try again later'))
            } else {
                reject(t('Unkmown error'))
            }
        })
        onFetchResponse(async () => {
            try {
                user.value = JSON.parse(data.value)

                localStorage.setItem('user', data.value)

                await updateUserPermissions()

                resolve()

                await router.push(returnUrl.value != '/' && returnUrl.value ? returnUrl.value : '/app/dashboard')

                returnUrl.value = null

            } catch(e) {
                ;
                reject(e)
            }
        })
    }

    const forgotPassword = async ({ form, resolve, reject }) => {
        const { onFetchError, onFetchResponse, statusCode } = resetPasswordTokenUnauthenticated(form.email, {
            clientUrl: `${window.location.protocol  }//${  window.location.host}`,
        })
        const { t } = i18n.global

        onFetchError(() => {
            if (statusCode.value === 401) {
                reject(t('Email or password is incorrect'))
            } else if (statusCode.value !== 200) {
                reject(t('Something went wrong. Please try again later'))
            }
        })
        onFetchResponse(() => {
            resolve()
        })
    }

    const reseUserPassword = async ({ form, resolve, reject }) => {
        const { onFetchError, onFetchResponse } = resetPassword({
            password: form.password,
            resetToken: previousToken.value,
        })
        const { t } = i18n.global

        onFetchError(() => {
            reject(t('An error has occurred or your link has expired.'))
        })
        onFetchResponse(() => {
            resolve()

            router.push('/login')
        })
    }

    return {
        user,
        returnUrl,
        reseUserPassword,
        logout,
        login,
        forgotPassword,
        setToken,
        updateUserPermissions,
        userPermissions,
        isAdmin,
    }
})

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
