// useApiError.js
import { ref } from 'vue'

const apiError = ref(null)
const apiBody = ref(null)

export function useApiError() {
    function setApiError(error, body) {
        apiError.value = error
        apiBody.value = body
    }

    return {
        apiError,
        apiBody,
        setApiError,
    }
}
