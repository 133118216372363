<script setup lang="ts">
const { t, locale } = useI18n({ useScope: 'global' })

const menu = ref([
    {
        name: 'footer.menu.terms',
    },
])

const isTermsVisible = ref(false)
const isPrivacyVisible = ref(false)

const onMenuItemClick = (item) => {
    if (item.index === 0) {
        isTermsVisible.value = true
    }

    if (item.index === 1) {
        isPrivacyVisible.value = true
    }
}

const changeLanguage = (lang: string) => {
    locale.value = lang

    menu.value[2].to = lang === 'en' ? 'https://payfacto.com/contact/' : 'https://payfacto.com/fr/contact/'
}
</script>

<template>
    <div class="h-full w-full overflow-auto">
        <GeneralLayout
            class="p-0 lg:p-6 h-auto min-h-full"
            :lang="locale"
            :menu="menu"
            @menu-item-click="onMenuItemClick"
            @switch-language="changeLanguage"
        >
            <RouterView></RouterView>
        </GeneralLayout>

        <TermsConditions v-model:visible="isTermsVisible" />
        <PrivacyPolicy v-model:visible="isPrivacyVisible" />
    </div>
</template>
